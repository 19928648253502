import * as ps from '../../vendors/photoswipe.min';
import * as psUi from '../../vendors/photoswipe-ui-default.min';
const PhotoSwipe = ps.default;
const PhotoSwipeUI_Default = psUi.default;

export default function openGalleryFromElementData(
  selector,
  dataKey,
  arrayIndex,
  galleryIndex,
  element,
) {
  let pswpElement = document.querySelectorAll('.pswp')[0],
    gallery,
    options,
    items,
    currentIndex;
  let fullData;
  const attrJson = document.querySelector(selector).dataset[dataKey];

  if (attrJson) {
    fullData = JSON.parse(attrJson);
  }
  currentIndex = galleryIndex;
  const title = '';

  items = [];

  if (fullData) {
    fullData.forEach((el) => {
      let sizeString = el.sizeString.split('x');
      items.push({
        src: el.src,
        w: parseInt(sizeString[0]),
        h: parseInt(sizeString[1]),
        title: `<h3>${title}</h3>`,
      });
    });
  } else {
    let sizeString = element.dataset.size.split('x');
    items.push({
      src: element.dataset.image,
      w: parseInt(sizeString[0]),
      h: parseInt(sizeString[1]),
      title: `<h3>${title}</h3>`,
    });
  }

  options = {
    // define gallery index (for URL)
    galleryUID: selector.slice(1),

    getThumbBoundsFn: function (index) {
      // See Options -> getThumbBoundsFn section of documentation for more info
      var thumbnail = element, // find thumbnail
        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
        rect = thumbnail.getBoundingClientRect();

      // console.log(rect)

      return {
        x: rect.left,
        y: rect.top + pageYScroll,
        w: rect.width,
      };
    },
  };

  // PhotoSwipe opened from URL
  options.index = parseInt(arrayIndex);

  // console.log(options)

  // exit if index not found
  if (isNaN(options.index)) {
    return;
  }

  // Pass data to PhotoSwipe and initialize it
  gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
  gallery.init();
}
