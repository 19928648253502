export default class BodyController {
  constructor($scope, $document) {
    this.scope = $scope;
    this.document = $document;
    this.scope.listClasses = ['close', 'close', 'close', 'close'];

    this.scope.openCloseRelList = (index)=> {
      console.log(index);
      for (let i = 0; i < 4; i++) {
        if (i == index) {
          this.scope.listClasses[index] =
            this.scope.listClasses[index] == 'close'
              ? (this.scope.listClasses[index] = 'open')
              : (this.scope.listClasses[index] = 'close');
        } else {
          this.scope.listClasses[i] = 'close';
        }
      }
    };
  }

  openCloseMenu(bool) {
    if (bool) {
      document.getElementById('dropMenu').classList.add('open-menu');
    } else {
      document.getElementById('dropMenu').classList.remove('open-menu');
    }
  }
}
