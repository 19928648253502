import * as ps from '../../vendors/photoswipe.min';
import * as psUi from '../../vendors/photoswipe-ui-default.min';
const PhotoSwipe = ps.default;
const PhotoSwipeUI_Default = psUi.default;

export default function clickOpen(element, id = 'image') {
  let gallery, options, items;
  const pswpElement = document.querySelectorAll('.pswp')[0];
  const sizeString = element.getAttribute('data-size').split('x');

  items = [
    {
      src: element.getAttribute('data-image'),
      w: parseInt(sizeString[0]),
      h: parseInt(sizeString[1]),
    },
  ];

  // define options (if needed)
  options = {
    // define gallery index (for URL)
    galleryUID: element.id || id,

    getThumbBoundsFn: function (index) {
      // See Options -> getThumbBoundsFn section of documentation for more info
      const thumbnail = element, // find thumbnail
        pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
        rect = thumbnail.getBoundingClientRect();

      // console.log(rect)

      return {
        x: rect.left,
        y: rect.top + pageYScroll,
        w: rect.width,
      };
    },
  };

  // PhotoSwipe opened from URL
  options.index = 0;

  // exit if index not found
  if (isNaN(options.index)) {
    return;
  }

  // Pass data to PhotoSwipe and initialize it
  gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
  gallery.init();
  // return gallery
}
